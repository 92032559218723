import { default as indexkMTsqntnhLMeta } from "/app/pages/design-system/index.vue?macro=true";
import { default as indexjolS2six6oMeta } from "/app/pages/drivers/index.vue?macro=true";
import { default as requesth81NiyMuosMeta } from "/app/pages/forgot-password/request.vue?macro=true";
import { default as reset6D4XG8PlA8Meta } from "/app/pages/forgot-password/reset.vue?macro=true";
import { default as _91id_93nOik8uqA49Meta } from "/app/pages/grouping-points/[id].vue?macro=true";
import { default as createG9INYjucHWMeta } from "/app/pages/grouping-points/create.vue?macro=true";
import { default as indexCFoS5x4F46Meta } from "/app/pages/grouping-points/index.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as daysnk0piIdAUrMeta } from "/app/pages/itineraries/days.vue?macro=true";
import { default as loginhHM0vSTW5jMeta } from "/app/pages/login.vue?macro=true";
import { default as indexH2hAD9uF8CMeta } from "/app/pages/parameters/index.vue?macro=true";
import { default as _91id_93TEi5BLyK6lMeta } from "/app/pages/parkings/[id].vue?macro=true";
import { default as createmtf754GVx1Meta } from "/app/pages/parkings/create.vue?macro=true";
import { default as indexoYLElURE4bMeta } from "/app/pages/parkings/index.vue?macro=true";
import { default as _91address_45id_93GEIcjr5al8Meta } from "/app/pages/passengers/[id]/addresses/[address-id].vue?macro=true";
import { default as createZ4FjxliUIrMeta } from "/app/pages/passengers/[id]/addresses/create.vue?macro=true";
import { default as edit514zGV0WdxMeta } from "/app/pages/passengers/[id]/edit.vue?macro=true";
import { default as indexEB4XmLXGVkMeta } from "/app/pages/passengers/[id]/index.vue?macro=true";
import { default as _91transport_45request_45id_93yxeJ7bR0auMeta } from "/app/pages/passengers/[id]/transport-requests/[transport-request-id].vue?macro=true";
import { default as createwVlCxr5MmMMeta } from "/app/pages/passengers/[id]/transport-requests/create.vue?macro=true";
import { default as create5joqj4YaLuMeta } from "/app/pages/passengers/create.vue?macro=true";
import { default as indexP2ylVCzxvOMeta } from "/app/pages/passengers/index.vue?macro=true";
import { default as index6b6HD0KoLvMeta } from "/app/pages/planification/index.vue?macro=true";
import { default as editVbCNi4ADyuMeta } from "/app/pages/structures/[id]/edit.vue?macro=true";
import { default as indexVSU2iCjyPdMeta } from "/app/pages/structures/[id]/index.vue?macro=true";
import { default as createXaOlAcvnKgMeta } from "/app/pages/structures/create.vue?macro=true";
import { default as indexDm2Oc8jcysMeta } from "/app/pages/structures/index.vue?macro=true";
import { default as indexsurkvrSAmBMeta } from "/app/pages/transport-requests/index.vue?macro=true";
import { default as _91id_935WvSy3EoyGMeta } from "/app/pages/unprocessed-transport-request/[id].vue?macro=true";
import { default as editSpBrfNTRAMMeta } from "/app/pages/vehicles/[id]/edit.vue?macro=true";
import { default as indextUeUklz6tpMeta } from "/app/pages/vehicles/[id]/index.vue?macro=true";
import { default as createTRbMvpqqADMeta } from "/app/pages/vehicles/create.vue?macro=true";
import { default as indexSSWNlDQNHMMeta } from "/app/pages/vehicles/index.vue?macro=true";
export default [
  {
    name: "design-system",
    path: "/design-system",
    component: () => import("/app/pages/design-system/index.vue").then(m => m.default || m)
  },
  {
    name: "drivers",
    path: "/drivers",
    component: () => import("/app/pages/drivers/index.vue").then(m => m.default || m)
  },
  {
    name: "forgot-password-request",
    path: "/forgot-password/request",
    meta: requesth81NiyMuosMeta || {},
    component: () => import("/app/pages/forgot-password/request.vue").then(m => m.default || m)
  },
  {
    name: "forgot-password-reset",
    path: "/forgot-password/reset",
    meta: reset6D4XG8PlA8Meta || {},
    component: () => import("/app/pages/forgot-password/reset.vue").then(m => m.default || m)
  },
  {
    name: "grouping-points-id",
    path: "/grouping-points/:id()",
    component: () => import("/app/pages/grouping-points/[id].vue").then(m => m.default || m)
  },
  {
    name: "grouping-points-create",
    path: "/grouping-points/create",
    component: () => import("/app/pages/grouping-points/create.vue").then(m => m.default || m)
  },
  {
    name: "grouping-points",
    path: "/grouping-points",
    component: () => import("/app/pages/grouping-points/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "itineraries-days",
    path: "/itineraries/days",
    meta: daysnk0piIdAUrMeta || {},
    component: () => import("/app/pages/itineraries/days.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: loginhHM0vSTW5jMeta || {},
    component: () => import("/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "parameters",
    path: "/parameters",
    component: () => import("/app/pages/parameters/index.vue").then(m => m.default || m)
  },
  {
    name: "parkings-id",
    path: "/parkings/:id()",
    component: () => import("/app/pages/parkings/[id].vue").then(m => m.default || m)
  },
  {
    name: "parkings-create",
    path: "/parkings/create",
    component: () => import("/app/pages/parkings/create.vue").then(m => m.default || m)
  },
  {
    name: "parkings",
    path: "/parkings",
    component: () => import("/app/pages/parkings/index.vue").then(m => m.default || m)
  },
  {
    name: "passengers-id-addresses-addressid",
    path: "/passengers/:id()/addresses/:addressid()",
    component: () => import("/app/pages/passengers/[id]/addresses/[address-id].vue").then(m => m.default || m)
  },
  {
    name: "passengers-id-addresses-create",
    path: "/passengers/:id()/addresses/create",
    component: () => import("/app/pages/passengers/[id]/addresses/create.vue").then(m => m.default || m)
  },
  {
    name: "passengers-id-edit",
    path: "/passengers/:id()/edit",
    component: () => import("/app/pages/passengers/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "passengers-id",
    path: "/passengers/:id()",
    component: () => import("/app/pages/passengers/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "passengers-id-transport-requests-transportrequestid",
    path: "/passengers/:id()/transport-requests/:transportrequestid()",
    component: () => import("/app/pages/passengers/[id]/transport-requests/[transport-request-id].vue").then(m => m.default || m)
  },
  {
    name: "passengers-id-transport-requests-create",
    path: "/passengers/:id()/transport-requests/create",
    component: () => import("/app/pages/passengers/[id]/transport-requests/create.vue").then(m => m.default || m)
  },
  {
    name: "passengers-create",
    path: "/passengers/create",
    component: () => import("/app/pages/passengers/create.vue").then(m => m.default || m)
  },
  {
    name: "passengers",
    path: "/passengers",
    component: () => import("/app/pages/passengers/index.vue").then(m => m.default || m)
  },
  {
    name: "planification",
    path: "/planification",
    component: () => import("/app/pages/planification/index.vue").then(m => m.default || m)
  },
  {
    name: "structures-id-edit",
    path: "/structures/:id()/edit",
    component: () => import("/app/pages/structures/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "structures-id",
    path: "/structures/:id()",
    component: () => import("/app/pages/structures/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "structures-create",
    path: "/structures/create",
    component: () => import("/app/pages/structures/create.vue").then(m => m.default || m)
  },
  {
    name: "structures",
    path: "/structures",
    component: () => import("/app/pages/structures/index.vue").then(m => m.default || m)
  },
  {
    name: "transport-requests",
    path: "/transport-requests",
    component: () => import("/app/pages/transport-requests/index.vue").then(m => m.default || m)
  },
  {
    name: "unprocessed-transport-request-id",
    path: "/unprocessed-transport-request/:id()",
    component: () => import("/app/pages/unprocessed-transport-request/[id].vue").then(m => m.default || m)
  },
  {
    name: "vehicles-id-edit",
    path: "/vehicles/:id()/edit",
    component: () => import("/app/pages/vehicles/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "vehicles-id",
    path: "/vehicles/:id()",
    component: () => import("/app/pages/vehicles/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "vehicles-create",
    path: "/vehicles/create",
    component: () => import("/app/pages/vehicles/create.vue").then(m => m.default || m)
  },
  {
    name: "vehicles",
    path: "/vehicles",
    component: () => import("/app/pages/vehicles/index.vue").then(m => m.default || m)
  }
]